import React from 'react';
import Loadable from 'react-loadable';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Header from '../../organisms/Header'
import Loader from '../../atoms/Loader'
import SideNavbar from '../../organisms/SideNavbar'
import utilCommon from '../../../helpers/utilCommon'
import userManagementService from '../../../services/api/userManagementService'
import carrierService from '../../../services/api/carrierService'
import loginService from '../../../services/api/loginService'
import applicationSettingsService from '../../../services/api/applicationSettingsService';

const UserManagement = Loadable({
	loader: () => import('../../pages/UserManagement'),
	loading: Loader,
});

const Teams = Loadable({
	loader: () => import('../../pages/Teams'),
	loading: Loader,
});

const CarrierManagement = Loadable({
	loader: () => import('../../pages/CarrierManagement'),
	loading: Loader,
});

const ContractManagement = Loadable({
	loader: () => import('../../pages/ContractManagement'),
	loading: Loader,
});

const AddCustomerManagement = Loadable({
	loader: () => import('../../pages/CustomerManagement'),
	loading: Loader,
});

const CustomerManagement = Loadable({
	loader: () => import('../../pages/CustomerManagement/CustomerList'),
	loading: Loader,
})
// shipping
const ShippingList = Loadable({
	loader: () => import('../../pages/ShippingManagement/ShippingList'),
	loading: Loader,
});

const ShippingManagementEdit = Loadable({
	loader: () => import('../../pages/ShippingManagement/ShippingManagementEdit'),
	loading: Loader,
})

const MyAccount = Loadable({
	loader: () => import('../../pages/MyAccount'),
	loading: Loader,
});

const TransitTimeSettings = Loadable({
	loader: () => import('../../pages/TransitTimeSettings'),
	loading: Loader,
});

const GlobalDiscount = Loadable({
	loader: () => import('../../pages/GlobalDiscount'),
	loading: Loader,
});

const RewardsSettingsComponent = Loadable({
	loader: () => import('../../pages/RewardsSettings'),
	loading: Loader,
});

const FuelSurcharge = Loadable({
	loader: () => import('../../pages/FuelSurcharge'),
	loading: Loader,
});


const FacilityType = Loadable({
	loader: () => import('../../pages/FacilityType'),
	loading: Loader,
});

const Accessorials = Loadable({
	loader: () => import('../../pages/Accessorials'),
	loading: Loader,
});


const QuoteManagement = Loadable({
	loader: () => import('../../pages/QuoteManagement'),
	loading: Loader,
})

const ManageCoupon = Loadable({
	loader: () => import('../../pages/ManageCoupon'),
	loading: Loader,
})

const AlertReport = Loadable({
	loader: () => import('../../pages/AlertReport'),
	loading: Loader,
})

const ProcessingReviewQueue = Loadable({
	loader: () => import('../../pages/ProcessingReviewQueue'),
	loading: Loader,
})

const SystemWideNotification = Loadable({
	loader: () => import('../../pages/SystemWideNotification'),
	loading: Loader,
})

const FalveyTermsConditions = Loadable({
	loader: () => import('../../pages/FalveyTermsConditions'),
	loading: Loader,
})
const TMSTermsConditions = Loadable({
	loader: () => import('../../pages/TMSTermsConditions'),
	loading: Loader,
})
const FinancialSettings = Loadable({
	loader: () => import('../../pages/FinancialSettings'),
	loading: Loader,
})
const ApprovedIP = Loadable({
	loader: () => import('../../pages/ApprovedIP'),
	loading: Loader,
})
const SecurityLevels = Loadable({
	loader: () => import('../../pages/SecurityLevels'),
	loading: Loader,
})
const SecurityRoles = Loadable({
	loader: () => import('../../pages/SecurityRoles'),
	loading: Loader,
})
const LeadSettings = Loadable({
	loader: () => import('../../pages/LeadSettings'),
	loading: Loader,
})
const LeadManagement = Loadable({
	loader: () => import('../../pages/LeadManagement'),
	loading: Loader,
})
const AdminLeadManagement = Loadable({
	loader: () => import('../../pages/AdminLeadManagement'),
	loading: Loader,
})
const CustomerDiscountReport = Loadable({
	loader: () => import('../../pages/CustomerDiscountReport'),
	loading: Loader,
})
const ShippersByAreaReport = Loadable({
	loader : () => import('../../pages/ShippersByAreaReport'),
	loading: Loader,
})
const ARCustomerReport = Loadable({
	loader : () => import('../../pages/ARCustomerReport'),
	loading: Loader,
})
const RegistrationReport = Loadable({
	loader: () => import('../../pages/RegistrationReport'),
	loading: Loader,
})
const CarrierPerformanceReport = Loadable({
	loader: () => import('../../pages/CarrierPerformanceReport'),
	loading: Loader,
})
const StatisticalReport = Loadable({
	loader: () => import('../../pages/StatisticalReport'),
	loading: Loader,
})

const DailyBookingReport = Loadable({
	loader: () => import('../../pages/DailyBookingReport'),
	loading: Loader,
})

const SalesTrackingReport = Loadable({
	loader: () => import('../../pages/SalesTrackingReport'),
	loading: Loader,
})
const NewBAQueue = Loadable({
	loader: () => import('../../pages/NewBAQueue'),
	loading: Loader,
})
const DashBoard = Loadable({
	loader: () => import('../../pages/Dashboard'),
	loading: Loader,
})
const Doc = Loadable({
	loader: () => import('../../pages/Doc'),
	loading: Loader,
})
const TopDogReport = Loadable({
	loader: () => import('../../pages/TopDogReport'),
	loading: Loader,
})
const AgentBonusReport = Loadable({
	loader: () => import('../../pages/AgentBonusReport'),
	loading: Loader,
})
const AgentBonusDetailedReport = Loadable({
	loader: () => import('../../pages/AgentBonusReport/Detailed'),
	loading: Loader,
})
const CoveredReport = Loadable({
	loader: () => import('../../pages/CoveredReport'),
	loading: Loader,
})
const AgentRevenueComparisonReport = Loadable({
	loader: () => import('../../pages/AgentRevenueComparisonReport'),
	loading: Loader,
})
const CustomerTrendReport = Loadable({
	loader: () => import('../../pages/CustomerTrendReport'),
	loading: Loader,
})
const InProgressBAQueue = Loadable({
	loader: () => import('../../pages/InProgressBAQueue'),
	loading: Loader
})
const CarrierDisputeQueue = Loadable({
	loader: () => import('../../pages/CarrierDispute'),
	loading: Loader
})
const ShipmentWithoutCarrierBill = Loadable({
	loader: () => import('../../pages/ShipmentWithoutCarrierBill'),
	loading: Loader
})
const DailySalesReport = Loadable({
	loader: () => import('../../pages/DailySalesReport'),
	loading: Loader
})
const AgentConversionReport = Loadable({
	loader: () => import('../../pages/AgentConversionReport'),
	loading: Loader
})
const AdminTool = Loadable({
	loader: () => import('../../pages/AdminTool'),
	loading: Loader
})

const FcQuoteResultsReport = Loadable({
	loader: () => import('../../pages/FcQuoteResultsReport'),
	loading: Loader,
})

const ProcessedBAQueue = Loadable({
	loader: () => import('../../pages/ProcessedBAQueue'),
	loading: Loader
})

const LeadsContactedReport = Loadable({
	loader: () => import('../../pages/LeadsContactedReport'),
	loading: Loader,
})

const MarketingEmails = Loadable({
	loader: () => import('../../pages/MarketingEmails'),
	loading: Loader,
})

const ExitIntentPopUp = Loadable({
	loader: () => import('../../pages/ApplicationSettings/ExitIntentPopUp'),
	loading: Loader,
})

const RegistrationReportSettings = Loadable({
	loader: () => import('../../pages/ApplicationSettings/RegistrationReportSettings'),
	loading: Loader,
})

class AdminDashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sideNavOpen: false,
			routeMap: [process.env.REACT_APP_BASEPATH]
		};
		// this.toggleSideNav = this.toggleSideNav.bind(this)
		// this.toggleNav = this.toggleNav.bind(this)
		// this.getUrl = this.getUrl.bind(this)
		// this.handleLogout = this.handleLogout.bind(this)
		// this.getUsers = this.getUsers.bind(this)
		// this.getCarriers = this.getCarriers.bind(this)
	}

	toggleSideNav = () => {
		this.setState({ sideNavOpen: !this.state.sideNavOpen })
	}

	toggleNav = () => {
		if (this.state.sideNavOpen) {
			this.setState({ sideNavOpen: !this.state.sideNavOpen })
		}
	}

	getUrl = (currentIndex) => {
		let url = '/'
		this.state.routeMap.map((route, index) => {
			if (index <= currentIndex) {
				url = index < currentIndex ? url + route + '/' : url + route
			}
		})
		return url
	}

	componentWillMount() {
		this.props.getMasterData()
		this.props.history.listen((location, action) => {
			let map = location.pathname.split('/')
			map.shift()
			this.setState({ routeMap: map.filter(x => x != "") })
		});
		this.callAPI('getNotification');
		// this.props.getUsers({
		// 	"status": 1,
		// 	"pageNumber": 1,
		// 	"pageSize": 1000,
		// 	"sortOrder": 'asc',
		// 	"sortColumn": 'name'
		// });
	}

	callAPI = (func, inputDTO) => {
	    this.props[func](this.props.currentLanguage, this.props.dictionaries, inputDTO)
    }

	unlisten = () => {
		this.props.history.listen((location, action) => {});
	}

	handleLogout = () => {
		this.setState({ accountMenuOpen: false });
		this.props.logout()
	};

	componentWillUnmount() {
		utilCommon.setSession('keyword', '')
		utilCommon.removeSession('redirect')
		this.unlisten();
	}

	getUsers = (inputDTO) => {
		//this.props.getUsers(inputDTO, this.props.currentLanguage, this.props.dictionaries)
	}

	getCarriers = (inputDTO) => {
		//this.props.getCarriers(inputDTO, this.props.currentLanguage, this.props.dictionaries)
	}
	getCustomers = (inputDTO) => {
		// this.props.getCustomers(inputDTO, this.props.currentLanguage, this.props.dictionaries)
	}

	componentDidMount(){
		document.title = 'Admin Dashboard'
	}

	render() {
		Window['history'] = this.props.history
		return (
			<div >
				<Header
					history={this.props.history}
					loading={this.props.loading}
					getUsers={this.getUsers}
					getCustomers={this.getCustomers}
					getCarriers={this.getCarriers}
					toggleNav={this.toggleNav}
					sideNavOpen={this.state.sideNavOpen}
					handleLogout={this.handleLogout}
					toggleSideNav={this.toggleSideNav}
				/>
				<SideNavbar location={this.props.location} history={this.props.history} toggleSideNav={this.toggleSideNav} sideNavOpen={this.state.sideNavOpen} />
				<div onClick={this.toggleNav} className="main-panel">
					<Route path={`${process.env.REACT_APP_BASEPATH}/`} exact component={DashBoard} />
					<Route path={`${process.env.REACT_APP_BASEPATH}/o-doc`} exact component={Doc} />
					{/* <Route path={`${process.env.REACT_APP_BASEPATH}/`} exact /> */}
					{utilCommon.isPermitted(1,1,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/user-management`} component={UserManagement} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/teams`} component={Teams} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/carrier-management`} component={CarrierManagement} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/contract-management`} component={ContractManagement} />}
					{utilCommon.isPermitted(2,6,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/customer-management`} exact component={CustomerManagement} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/add-customer-management`} component={AddCustomerManagement} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/customer-management/:id`} exact component={AddCustomerManagement} />}


					{ (utilCommon.isPermitted(4,18,1) || utilCommon.isPermitted(7,57,1) )&&
						<Route path={`${process.env.REACT_APP_BASEPATH}/shipment-management`} exact component={ShippingList} />
					}


					{utilCommon.isPermitted(4,18,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/customer/shipment-management/:id/:status`} exact component={ShippingList} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/shipment-management/:id`} exact component={ShippingManagementEdit} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/my-account`} component={MyAccount} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/transit-time`} component={TransitTimeSettings} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/global-discount`} component={GlobalDiscount} />}
					{utilCommon.isPermitted(1, 1, 1) &&
						<Route path={`${process.env.REACT_APP_BASEPATH}/rewards-settings`} component={RewardsSettingsComponent}/>}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/fuel-surcharge`} component={FuelSurcharge} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/facility-type`} component={FacilityType} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/accessorials`} component={Accessorials} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/quote`} component={QuoteManagement} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/manage-coupon`} component={ManageCoupon} />}
					{utilCommon.isPermitted(1,1,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/marketing-emails`} component={MarketingEmails} />}
					{utilCommon.isPermitted(3,52,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/alert-report`} component={AlertReport} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/system-wide-notification`} component={SystemWideNotification} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/falvey-terms-conditions`} component={FalveyTermsConditions} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/tms-terms-conditions`} component={TMSTermsConditions} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/financial-settings`} component={FinancialSettings} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/approved-ip`} component={ApprovedIP} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/security-levels`} component={SecurityLevels} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/security-roles`} component={SecurityRoles} />}
					{utilCommon.isPermitted(1,1,1) &&<Route path={`${process.env.REACT_APP_BASEPATH}/lead-settings`} component={LeadSettings} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/lead-management/:id`} exact component={LeadManagement} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/lead-management`} exact component={LeadManagement} />}
					{utilCommon.isPermitted(5,82,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/admin-leads`} exact component={AdminLeadManagement} />}
					{utilCommon.isPermitted(5,83,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/leads-contacted-report`} exact component={LeadsContactedReport} />}
					{utilCommon.isPermitted(3,17,1) &&  <Route path={`${process.env.REACT_APP_BASEPATH}/processing-review-queue`} component={ProcessingReviewQueue} />}
					{utilCommon.isPermitted(6, 44, 1) && <Route path={`${process.env.REACT_APP_BASEPATH}/daily-booking-report`} component={DailyBookingReport} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/customer-discount-report`} component={CustomerDiscountReport} />}
					{utilCommon.isPermitted(3,89,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/shippers-by-area-report`} component={ShippersByAreaReport} />}
					{utilCommon.isPermitted(6,45,1) &&  <Route path={`${process.env.REACT_APP_BASEPATH}/customer-ar-report`} component={ARCustomerReport} />}
					{utilCommon.isPermitted(3,90,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/registration-reports`} component={RegistrationReport} />}
					{utilCommon.isPermitted(3,88,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/sales-tracking-report`} component={SalesTrackingReport} />}
					{utilCommon.isPermitted(3,91,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/carrier-performance-report`} component={CarrierPerformanceReport} />}
					{utilCommon.isPermitted(3,92,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/statistical-reports`} component={StatisticalReport} />}
					{utilCommon.isPermitted(3,14,1) &&  <Route path={`${process.env.REACT_APP_BASEPATH}/new-ba-queue`} component={NewBAQueue} />}
					{utilCommon.isPermitted(3,15,1) &&  <Route path={`${process.env.REACT_APP_BASEPATH}/in-progress-ba-queue`} component={InProgressBAQueue} />}
					{utilCommon.isPermitted(3,51,1)
					&&  <Route path={`${process.env.REACT_APP_BASEPATH}/processed-ba-queue`} component={ProcessedBAQueue} />}
					<Route path={`${process.env.REACT_APP_BASEPATH}/shipment-without-carrier-bill`} component={ShipmentWithoutCarrierBill} />
					{utilCommon.isPermitted(3,93,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/top-dog-report`} component={TopDogReport} />}
					{utilCommon.isPermitted(3,97,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/agent-bonus-report`} exact render={(routeProps) => <AgentBonusReport {...routeProps} />} />}
					{utilCommon.isPermitted(3,97,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/agent-bonus-detailed-report`} exact render={(routeProps) => <AgentBonusDetailedReport {...routeProps} />} />}
					{<Route path={`${process.env.REACT_APP_BASEPATH}/covered-report`} component={CoveredReport} />}
					{utilCommon.isPermitted(3,94,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/agent-revenue-comparison-report`} component={AgentRevenueComparisonReport} />}
					{utilCommon.isPermitted(3,95,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/customer-trend-report`} component={CustomerTrendReport} />}
					{utilCommon.isPermitted(3,96,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/daily-sales-report`} component={DailySalesReport} />}
					{utilCommon.isPermitted(3,97,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/agent-conversion-report`} component={AgentConversionReport} />}
					{utilCommon.isPermitted(3,98,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/fc-quote-results-report`} component={FcQuoteResultsReport} />}
					{utilCommon.isPermitted(1,2,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/admin-tool`} component={AdminTool} />}
					{utilCommon.isPermitted(1,2,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/exit-intent-pop-up`} component={ExitIntentPopUp} />}
					{utilCommon.isPermitted(1,103,1) && <Route path={`${process.env.REACT_APP_BASEPATH}/registration-report-settings`} component={RegistrationReportSettings} />}

				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	currentLanguage: state.i18nReducer.currentLanguage,
	dictionaries: state.i18nReducer.dictionaries,
	loading: state.common.loading
});

const mapDispatchToProps = dispatch => ({
	getUsers: (inputDTO, currentLanguage, dictionaries) => {
		userManagementService.getUsers(dispatch, currentLanguage, dictionaries, inputDTO)
	},
	getCarriers: (inputDTO, currentLanguage, dictionaries) => {
		carrierService.getCarrriers(dispatch, currentLanguage, dictionaries, inputDTO)
	},
	getMasterData: () => {
		loginService.getMasterData(dispatch)
	},
	getNotification: (currentLanguage, dictionaries) => {
        applicationSettingsService.getNotification(dispatch, currentLanguage, dictionaries)
	},
	logout : ()=>{
		loginService.logout(dispatch)
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon from '../../helpers/utilCommon'
import * as commonActions from '../../store/common/actions'
import * as type from '../../store/leads/actionTypes'
import userManagementService from '../api/userManagementService'
import lodash from 'lodash'


export const getLeadSettingsParams = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'getLeadSettings');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_LEADSETTINGS_PARAMS, leadSettings: response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const setLeadSettings = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'setLeadSettings');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Lead settings updated successfully.', lang, dictionary)
                getLeadSettings(dispatch, lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getLeadSettings = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'setLeadSettings');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_LEADSETTINGS, leadSettings: response.data.content })
            } else {
                // response.data.errors.map((error) => {
                //     utilCommon.showNotification(error, dictionary, lang)
                // })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getAllLeads = (dispatch, lang, dictionary, inputDTO = {}) => {
    if (
        _.get(inputDTO, 'searchParam.leadStatus') &&
        _.indexOf(inputDTO.searchParam.leadStatus, 'null') >= 0
    ) {
        inputDTO.searchParam.leadStatus.push(1);
    }
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'getAllLeads');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.leadUuid) {
                    dispatch({ type: type.SET_LEADS_DETAILS, leadDetails: response.data.content.leadDetails })
                } else if (inputDTO.customerUuid) {
                    dispatch({ type: type.SET_LEADS_DETAILS, leadDetails: response.data.content.customerDetails ? response.data.content.customerDetails : null })
                } else {
                    dispatch({ type: type.SET_ALL_LEADS, allLeads: response.data.content.leadList, totalCount: response.data.content.leadCount })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getLeadStatusMaster = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'getAllLeadsStatusMaster');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_LEADS_STATUS_MASTER, stausMaster: response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getLeadManagementReviewReasonMaster = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'getAllManagementReviewReason');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data) {
                dispatch({type: type.SET_LEADS_MANAGEMENT_REVIEW_REASON_MASTER, managementReviewReasonMaster: response.data})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const bulkUpdateLeads = (dispatch, lang, dictionary, inputDTO, filterParam?) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'bulkUpdateLeads');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                const requestParams = {
                    pageNumber: filterParam.pageNumber,
                    pageSize: filterParam.pageSize,
                    sortOrder: filterParam.sortOrder,
                    sortColumn: filterParam.sortColumn,
                    needCount: 1,
                    searchParam: filterParam.searchParam,
                };

                getAllLeads(dispatch, lang, dictionary, requestParams);

                if (lodash.get(inputDTO, 'leadUuid')) {
                    getAllLeads(dispatch, lang, dictionary, {leadUuid: inputDTO.leadUuid[0]});
                }

                utilCommon.showNotification('Lead(s) updated successfully', lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const updateLeadSettings = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'updateLeadSettings');

    dispatch(commonActions.setLoader(true))

    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))

            if (response.data.success) {
                utilCommon.showNotification('Lead settings updated successfully', lang, dictionary)
                userManagementService.getUserDetails(dispatch, lang, dictionary, inputDTO.userId)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getAdminLeads = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('leads', 'getAdminLeads');
    url = url + '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder + '&needCount=1'
    dispatch({ type: type.SET_LOADER, flag: true });
    axios.get(url, params)
        .then((response) => {
            dispatch({ type: type.SET_LOADER, flag: false });
            if (response.data.success) {
                dispatch({ type: type.SET_ADMIN_LEADS, adminLeads: response.data.content.leadList, adminLeadCount: response.data.content.leadCount })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};
// get lead agent list for popup
export const getLeadAgents = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('leads', 'getAdminLeadQuota');

    if (lodash.get(inputDTO, 'leadTypeId')) {
        url = `${url}?leadTypeId=${inputDTO.leadTypeId}`
    } else if (lodash.get(inputDTO, 'leadSubType')) {
        url = `${url}?leadSubType=${inputDTO.leadSubType}`
    } else if (lodash.get(inputDTO, 'leadMode')) {
        url = `${url}?leadMode=${inputDTO.leadMode}`
    }

    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_LEAD_AGENTS, leadAgents: response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const updateLeadStatus = (dispatch, lang, dictionary, inputDTO, filterParam?) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'updateAdminLead');
    dispatch(commonActions.setLoader(true));

    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_STATUS_FLAG, statusFlag: true })
                getAdminLeads(
                    dispatch,
                    lang,
                    dictionary,
                    {
                        "pageNumber": filterParam.pageNumber,
                        "pageSize": filterParam.pageSize,
                        "sortOrder": filterParam.sortOrder,
                        "sortColumn": filterParam.sortColumn,
                        "needCount": 1
                    }
                );
                utilCommon.showNotification('Lead(s) status updated successfully', lang, dictionary)


            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// get admin lead quota
export const getAdminLeadQuota = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('leads', 'getAdminLeadQuota');


    axios.get(url, params)
        .then((response) => {

            if (response.data.success) {
                dispatch({ type: type.SET_ADMIN_LEAD_QUOTA, adminLeadQuota: response.data.content[0] })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getLeadsContactedReport = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('leads', 'getLeadsContactedReport');
    url += '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder + '&needCount=1'
    if (inputDTO.dateRange && inputDTO.dateRange !== "0") {
        if (inputDTO.dateRange.startDate) {
            url += '&dateRangeFrom=' + inputDTO.dateRange.startDate
            url += '&dateRangeTo=' + inputDTO.dateRange.endDate
        } else {
            url += '&dateRange=' + inputDTO.dateRange
        }
    }

    if (inputDTO.agentUuid && inputDTO.agentUuid.filter(x => x != "0").length > 0) {
        url += '&agentUserUuid=' + JSON.stringify(inputDTO.agentUuid)
    }

    if (inputDTO.agentUuidTeam && inputDTO.agentUuidTeam.filter(x => x != "0").length > 0) {
        url += '&agentUserUuidTeam=' + JSON.stringify(inputDTO.agentUuidTeam)
    }

    dispatch({ type: type.SET_LOADER, flag: true });
    axios.get(url, params)
        .then((response) => {
            dispatch({ type: type.SET_LOADER, flag: false });
            if (response.data.success) {
                dispatch(
                    {
                        type: type.SET_LEADS_CONTACTED,
                        leadsContacted: response.data.data.leadsContacted,
                        leadsContactedCount: response.data.data.leadsContactedCount,
                        totalUsers: response.data.data.totalUsers
                    })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const exportLeadsContactedReport = (dispatch, inputDTO) => {
    const params = {headers: authHeader(), responseType: 'blob'};
    let url = getApiUrl('leads', 'getLeadsContactedReport');
    url += '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder + '&needCount=1'
    if (inputDTO.dateRange && inputDTO.dateRange !== "0") {
        if (inputDTO.dateRange.startDate) {
            url += '&dateRangeFrom=' + inputDTO.dateRange.startDate
            url += '&dateRangeTo=' + inputDTO.dateRange.endDate
        } else {
            url += '&dateRange=' + inputDTO.dateRange
        }
    }

    if (inputDTO.agentUuid && inputDTO.agentUuid.filter(x => x != "0").length > 0) {
        url += '&agentUserUuid=' + JSON.stringify(inputDTO.agentUuid)
    }

    if (inputDTO.agentUuidTeam && inputDTO.agentUuidTeam.filter(x => x != "0").length > 0) {
        url += '&agentUserUuidTeam=' + JSON.stringify(inputDTO.agentUuidTeam)
    }

    url += '&excel=1'

    dispatch({ type: type.SET_LOADER, flag: true });
    axios.get(url, params)
        .then((response) => {
            let blob = new Blob([response.data], {type:response.data.type});
            saveAs(blob, `${inputDTO.reportName}.xlsx`);
            dispatch(commonActions.setLoader(false))
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const purgeLeads = (dispatch, lang, dictionary, inputDTO, filterParam?) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('leads', 'purgeLeads');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                const requestParams = {
                    pageNumber: filterParam.pageNumber,
                    pageSize: filterParam.pageSize,
                    sortOrder: filterParam.sortOrder,
                    sortColumn: filterParam.sortColumn,
                    needCount: 1,
                    searchParam: filterParam.searchParam,
                };

                getAllLeads(dispatch, lang, dictionary, requestParams);
                utilCommon.showNotification('Lead(s) purged successfully', lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}


export default {
    getLeadSettingsParams,
    getLeadSettings,
    setLeadSettings,
    getAllLeads,
    getLeadStatusMaster,
    getLeadManagementReviewReasonMaster,
    bulkUpdateLeads,
    updateLeadSettings,
    getAdminLeads,
    getLeadAgents,
    updateLeadStatus,
    getAdminLeadQuota,
    getLeadsContactedReport,
    exportLeadsContactedReport,
    purgeLeads
};



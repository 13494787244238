import React from 'react';
import PropTypes from 'prop-types';
import {Collapse, withStyles, List, ListItem, ListItemText} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {Scrollbars} from 'react-custom-scrollbars';
import utilCommon, {buildQueryString} from '../../../helpers/utilCommon'

const Link = require('react-router-dom').Link;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    },
    popperClose: {
        pointerEvents: 'none',
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class SideNavbar extends React.Component {
    state = {
        open: false,
        listopen: false,
        flyopen: false,
        customerlistopen: false,
        customerflyopen: false,
        settingflyopen: false,
        navListOpen: [false, false, false, false, false, false, false, false],
        navFlyOpen: [false, false, false, false, false, false, false, false]
    };

    handleToggle = () => {
        this.setState(state => ({open: !state.open}));
    };

    handleClose = event => {
        if (this.target1.contains(event.target)) {
            return;
        }

        this.setState({open: false});
    };

    handleClick = (index) => {
        if (index && index === 1) {
            this.setState(state => ({customerlistopen: !state.customerlistopen}));
        } else if (index && index >= 2) {
            let openFlag = this.state.navListOpen;
            openFlag[index] = !openFlag[index];
            this.setState(state => ({navListOpen: openFlag}));
        } else {
            this.setState(state => ({listopen: !state.listopen}));
        }

    };

    toggleFlyout = (flag, index) => {
        if (index && index === 1) {
            this.setState({
                customerflyopen: flag,
                flyopen: false,
                navFlyOpen: this.state.navFlyOpen.map((index, x) => false)
            })
        } else if (index && index >= 2) {
            let openFlag = this.state.navFlyOpen.slice();
            let value = !openFlag[index]
            openFlag = this.state.navFlyOpen.map((index, x) => false)
            openFlag[index] = value;
            this.setState(state => ({
                navFlyOpen: openFlag.slice(),
                flyopen: false,
                customerflyopen: false,
                settingflyopen: false
            }));
        } else {
            this.setState({
                flyopen: flag,
                customerflyopen: false,
                navFlyOpen: (this.state.navFlyOpen.map((index, x) => false))
            })
        }

    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScrollWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollWindow);
    }

    handleScrollWindow = () => {
        if (window.pageYOffset >= 54) {
            document.getElementById("left-sidebar-nav").classList.add('fixedLayoutLeftNav')
        } else if (window.pageYOffset <= 53) {
            document.getElementById("left-sidebar-nav").classList.remove('fixedLayoutLeftNav')
        }
    }

    getCurrentPath = (fullPath = false) => {
        const {location} = this.props;
        if (location)
            return fullPath === true ? location.pathname + location.search : location.pathname;
        else {
            return ''
        }
    }

    getTlParams() {
        const params = {
            shipmentMode: [3],
            agentUuid: [utilCommon.getSession('userUuid')]
        }

        return buildQueryString(params);
    }

    getLtlParams() {
        const params = {
            shipmentMode: [1],
            agentUuid: [utilCommon.getSession('userUuid')]
        }

        return buildQueryString(params);
    }

    render() {
        const {classes} = this.props;
        const currentPath = this.getCurrentPath()
        const currentFullPath = this.getCurrentPath(true);

        return (
            <div className={this.props.sideNavOpen ? "left-sidebar-nav sideNav-active" : "left-sidebar-nav"}
                 onMouseLeave={() => {
                     this.toggleFlyout(false)
                 }} id="left-sidebar-nav">
                <div>
                    <List component="div" className="listWrap">
                        <ListItem component="div" className="listItem" onClick={() => {
                            this.props.history.push(process.env.REACT_APP_BASEPATH)
                        }}>
                            <i className="grey" onMouseOver={() => {
                                this.toggleFlyout(true, 11)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20"
                                     className="grey">
                                    <path
                                        d="M22.089 7.393c-.607-1.495-1.425-2.783-2.451-3.863A11.53 11.53 0 0 0
                                        15.967.95c-1.42-.64-2.91-.96-4.467-.96-1.557 0-3.046.32-4.467.96-1.42.64-2.643
                                        1.499-3.67 2.58C2.336 4.61 1.519 5.898.91 7.393a12.369 12.369 0 0 0-.911 4.7c0
                                        2.36.603 4.534 1.81 6.525a.772.772 0 0 0 .693.391h17.994c.3 0 .53-.13.693-.391
                                        1.207-2 1.81-4.174 1.81-6.525 0-1.638-.304-3.205-.911-4.7zm-11.75-5.167A1.545
                                        1.545 0 0 1 11.5 1.72c.454 0 .84.169 1.162.506.32.338.481.745.481 1.223 0
                                        .477-.16.884-.482 1.222-.32.338-.707.507-1.161.507-.454 0-.84-.17-1.162-.507a1.711
                                        1.711 0 0 1-.48-1.222c0-.478.16-.885.48-1.223zm-5.892 11.09c-.32.338-.708.507-1.161.507-.454
                                        0-.84-.17-1.162-.507a1.71 1.71 0 0 1-.481-1.223c0-.477.16-.884.481-1.222.321-.338.708-.507
                                        1.162-.507.453 0 .84.17 1.161.507.321.338.482.745.482 1.222 0 .478-.16.885-.482
                                        1.223zm2.465-6.052a1.545 1.545 0 0 1-1.162.507c-.454 0-.84-.169-1.162-.507a1.71 1.71
                                        0 0 1-.48-1.222c0-.477.16-.885.48-1.222a1.545 1.545 0 0 1 1.162-.507c.454 0 .84.17
                                        1.162.507.32.337.48.745.48 1.222s-.16.885-.48 1.222zm7.27.115l-1.296 5.16c.428.306.736.716.924
                                        1.23.188.513.214 1.04.077 1.58-.17.693-.552 1.22-1.142 1.58-.59.36-1.215.45-1.874.27s-1.16-.58-1.502-1.202a2.641
                                        2.641 0 0 1-.256-1.972c.137-.54.406-.984.808-1.33.402-.347.86-.543 1.374-.588l1.296-5.16a.849.849
                                        0 0 1 .385-.534.733.733 0 0 1 .616-.087.82.82 0 0 1 .494.398.892.892 0 0 1 .096.655zm1.906-.115a1.71
                                        1.71 0 0 1-.481-1.222c0-.477.16-.885.482-1.222.32-.338.707-.507 1.161-.507.454 0
                                         .84.17 1.162.507.32.337.481.745.481 1.222s-.16.885-.482 1.222c-.32.338-.707.507-1.161.507-.454
                                         0-.84-.169-1.162-.507zm4.788 6.052c-.321.338-.708.507-1.162.507-.453 0-.84-.17-1.161-.507a1.71 1.71
                                         0 0 1-.482-1.223c0-.477.16-.884.482-1.222.32-.338.708-.507 1.161-.507.454 0 .84.17
                                         1.162.507.32.338.481.745.481 1.222 0 .478-.16.885-.481 1.223z"/>
                                </svg>
                            </i>
                            <ListItemText component="a" inset primary="Dashboard" className="linkTxt"/>
                        </ListItem>
                        {/* TL Shipment Toggle  */}
                        {utilCommon.isPermitted(7, 57, 1) &&
                        <ListItem component="div"
                                  className={this.state.navFlyOpen[7] ? "listItem hascollapseContent active" : "listItem hascollapseContent"}>
                            <i onClick={() => this.props.toggleSideNav()} onMouseOver={() => {
                                this.toggleFlyout(true, 7)
                            }} className="grey">
                                TL
                            </i>
                            <ListItemText onClick={() => this.handleClick(7)} inset primary="TL Shipments"
                                          className="linkTxt"/>
                            {this.state.navListOpen[7] ? <ExpandLess onClick={() => this.handleClick(7)}/> :
                                <ExpandMore onClick={() => this.handleClick(7)}/>}
                        </ListItem>}
                        <Collapse in={this.state.navListOpen[7]} timeout="auto" unmountOnExit
                                  className="collapseContent">
                            <List onClick={() => this.props.toggleSideNav()} component="div" disablePadding
                                  className="scdlistWrap">
                                {utilCommon.isPermitted(7, 57, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentFullPath.includes('shipmentMode[]=3') ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/shipment-management?${this.getTlParams()}`}>
                                            <ListItemText inset primary="My TL Shipments" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                                {utilCommon.isPermitted(7, 57, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentFullPath === `${process.env.REACT_APP_BASEPATH}/quote?shipmentMode=1` ? "active" : ''}
                                        to={{
                                            pathname: process.env.REACT_APP_BASEPATH + "/quote",
                                            search: "?shipmentMode=3"
                                        }}>
                                        <ListItemText inset primary="New TL Shipment" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                            </List>
                        </Collapse>
                        {/* End Of TL Shipment Toggle  */}
                        {/* LTL Shipment Toggle  */}
                        {utilCommon.isPermitted(4, 18, 1) &&
                        <ListItem component="div"
                                  className={this.state.navFlyOpen[2] ? "listItem hascollapseContent active" : "listItem hascollapseContent"}>
                            <i onClick={() => this.props.toggleSideNav(1)} onMouseOver={() => {
                                this.toggleFlyout(true, 2)
                            }} className="zmdi zmdi-truck grey"/>
                            <ListItemText onClick={() => this.handleClick(2)} inset primary="LTL Shipments"
                                          className="linkTxt"/>
                            {this.state.navListOpen[2] ? <ExpandLess onClick={() => this.handleClick(2)}/> :
                                <ExpandMore onClick={() => this.handleClick(2)}/>}
                        </ListItem>}
                        <Collapse in={this.state.navListOpen[2]} timeout="auto" unmountOnExit
                                  className="collapseContent">
                            <List onClick={() => this.props.toggleSideNav(1)} component="div" disablePadding
                                  className="scdlistWrap">
                                {utilCommon.isPermitted(4, 18, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentFullPath.includes('shipmentMode[]=1')  ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/shipment-management?${this.getLtlParams()}`}>
                                            <ListItemText inset primary="My LTL Shipments" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentFullPath === `${process.env.REACT_APP_BASEPATH}/quote?shipmentMode=1` ? "active" : ''}
                                        to={{
                                            pathname: process.env.REACT_APP_BASEPATH + "/quote",
                                            search: "?shipmentMode=1"
                                        }}
                                    >
                                        {/* Changed to New Shipment (justin comment) */}
                                        <ListItemText inset primary="New LTL Shipment" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>
                            </List>
                        </Collapse>
                        {/* End Of LTL Shipment Toggle  */}
                        <ListItem component="div"
                                  className={this.state.customerflyopen ? "listItem hascollapseContent active" : "listItem hascollapseContent"}>
                            <i onClick={() => this.props.toggleSideNav(1)} onMouseOver={() => {
                                this.toggleFlyout(true, 1)
                            }} className="zmdi zmdi-account grey"/>

                            <ListItemText onClick={() => this.handleClick(1)} inset primary="Customers"
                                          className="linkTxt"/>
                            {this.state.customerlistopen ? <ExpandLess onClick={() => this.handleClick(1)}/> :
                                <ExpandMore onClick={() => this.handleClick(1)}/>}
                        </ListItem>
                        <Collapse in={this.state.customerlistopen} timeout="auto" unmountOnExit
                                  className="collapseContent">
                            <List onClick={() => this.props.toggleSideNav(1)} component="div" disablePadding
                                  className="scdlistWrap">
                                {utilCommon.isPermitted(2, 6, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/customer-management` ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/customer-management`}>
                                            <ListItemText inset primary="My Customers" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/add-customer-management` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/add-customer-management`}>
                                        <ListItemText inset primary="Add Customer" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>
                            </List>
                        </Collapse>
                        {/* Reports Submenu */}
                        <ListItem component="div"
                                  className={this.state.navFlyOpen[3] ? "listItem hascollapseContent active" : "listItem hascollapseContent"}>
                            <i onClick={() => this.props.toggleSideNav(1)} onMouseOver={() => {
                                this.toggleFlyout(true, 3)
                            }} className="zmdi zmdi-assignment grey"/>
                            <ListItemText component="a" onClick={() => this.handleClick(3)} inset primary="Reports"
                                          className="linkTxt"/>
                            {this.state.navListOpen[3] ? <ExpandLess onClick={() => this.handleClick(3)}/> :
                                <ExpandMore onClick={() => this.handleClick(3)}/>}
                        </ListItem>
                        <Collapse in={this.state.navListOpen[3]} timeout="auto" unmountOnExit
                                  className="collapseContent">
                            <List onClick={() => this.props.toggleSideNav(1)} component="div" disablePadding
                                  className="scdlistWrap">
                                {utilCommon.isPermitted(3, 52, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/alert-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/alert-report`}>
                                        <ListItemText inset primary="Alert Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 89, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/shippers-by-area-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/shippers-by-area-report`}>
                                        <ListItemText inset primary="Frequent Shippers by Area" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 17, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/processing-review-queue` ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/processing-review-queue`}>
                                            <ListItemText inset primary="Processing Review Queue"
                                                          className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                                {utilCommon.isPermitted(3, 88, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/sales-tracking-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/sales-tracking-report`}>
                                        <ListItemText inset primary="Sales Tracking Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 90, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/registration-reports` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/registration-reports`}>
                                        <ListItemText inset primary="Registration Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 91, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/carrier-performance-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/carrier-performance-report`}>
                                        <ListItemText inset primary="Carrier Performance Report"
                                                      className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 92, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/statistical-reports` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/statistical-reports`}>
                                        <ListItemText inset primary="Statistical Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 93, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/top-dog-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/top-dog-report`}>
                                        <ListItemText inset primary="Top Dog Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 97, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/agent-bonus-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/agent-bonus-report`}>
                                        <ListItemText inset primary="Agent Bonus Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3,81,1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/covered-report` ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/covered-report`}>
                                            <ListItemText inset primary="Covered Report" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>
                                }
                                {utilCommon.isPermitted(3, 94, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/agent-revenue-comparison-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/agent-revenue-comparison-report`}>
                                        <ListItemText inset primary="Agent Revenue Comparison Report"
                                                      className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 95, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/customer-trend-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/customer-trend-report`}>
                                        <ListItemText inset primary="Customer Trend Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 96, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/daily-sales-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/daily-sales-report`}>
                                        <ListItemText inset primary="Daily Sales Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 97, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/agent-conversion-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/agent-conversion-report`}>
                                        <ListItemText inset primary="Agent Conversion Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {utilCommon.isPermitted(3, 98, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/fc-quote-results-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/fc-quote-results-report`}>
                                        <ListItemText inset primary="FC Quote Results Report" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                            </List>
                        </Collapse>
                        {/* Accounting starts */}
                        <ListItem component="div"
                                  className={this.state.navFlyOpen[5] ? "listItem hascollapseContent active" : "listItem hascollapseContent"}>
                            <i onClick={() => this.props.toggleSideNav(1)} onMouseOver={() => {
                                this.toggleFlyout(true, 5);
                            }} className="zmdi zmdi-money grey"/>
                            <ListItemText component="a" onClick={() => this.handleClick(5)} inset primary="Accounting"
                                          className="linkTxt"/>
                            {this.state.navListOpen[5] ? <ExpandLess onClick={() => this.handleClick(5)}/> :
                                <ExpandMore onClick={() => this.handleClick(5)}/>}
                        </ListItem>
                        <Collapse in={this.state.navListOpen[5]} timeout="auto" unmountOnExit
                                  className="collapseContent">
                            <List onClick={() => this.props.toggleSideNav(1)} component="div" disablePadding
                                  className="scdlistWrap">
                                {utilCommon.isPermitted(6, 45, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/customer-ar-report` ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/customer-ar-report`}>
                                            <ListItemText inset primary="Customer AR Report" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                                {utilCommon.isPermitted(6, 44, 1) &&
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle "/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/daily-booking-report` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/daily-booking-report`}>
                                        <ListItemText inset primary="Daily Bookings with Insurance Report"
                                                      className="scdlistTxt"/>
                                    </Link>
                                </ListItem>}
                                {(utilCommon.isPermitted(3, 41, 1) || utilCommon.isPermitted(3, 14, 1) || utilCommon.isPermitted(3, 15, 1) || utilCommon.isPermitted(3, 16, 1)) &&
                                    <ListItem className={classes.nested} component="div"
                                              className="scdlistItem subHead">
                                        <i className="zmdi zmdi-circle"/>
                                        <ListItemText inset primary="Billing Adjustment" className="scdlistTxt"/>
                                    </ListItem>}
                                {(utilCommon.isPermitted(3, 41, 1) || utilCommon.isPermitted(3, 14, 1) || utilCommon.isPermitted(3, 15, 1) || utilCommon.isPermitted(3, 16, 1)) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem lavel-2"
                                              onClick={() => this.props.toggleSideNav()}>
                                        {/* Submenu starts BA */}
                                        {utilCommon.isPermitted(3, 14, 1) &&
                                            <ListItem className={classes.nested} component="div"
                                                      className="scdlistItem">
                                                <i className="zmdi zmdi-circle "/>
                                                <Link
                                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/new-ba-queue` ? "active" : ''}
                                                    to={`${process.env.REACT_APP_BASEPATH}/new-ba-queue`}>
                                                    <ListItemText inset primary="New BA Queue" className="scdlistTxt"/>
                                                </Link>
                                            </ListItem>}
                                        {utilCommon.isPermitted(3, 15, 1) &&
                                            <ListItem className={classes.nested} component="div"
                                                      className="scdlistItem">
                                                <i className="zmdi zmdi-circle "/>
                                                <Link
                                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/in-progress-ba-queue` ? "active" : ''}
                                                    to={`${process.env.REACT_APP_BASEPATH}/in-progress-ba-queue`}>
                                                    <ListItemText inset primary="In Progress BA Queue"
                                                                  className="scdlistTxt"/>
                                                </Link>
                                            </ListItem>}
                                        {utilCommon.isPermitted(3, 51, 1) &&
                                            <ListItem className={classes.nested} component="div"
                                                      className="scdlistItem">
                                                <i className="zmdi zmdi-circle "/>
                                                <Link
                                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/processed-ba-queue` ? "active" : ''}
                                                    to={`${process.env.REACT_APP_BASEPATH}/processed-ba-queue`}>
                                                    <ListItemText inset primary="Completed BA Queue"
                                                                  className="scdlistTxt"/>
                                                </Link>
                                            </ListItem>}
                                    </ListItem>}
                            </List>
                        </Collapse>
                        {/* Lead Menu Starts */}
                        <ListItem component="div"
                                  className={this.state.navFlyOpen[4] ? "listItem hascollapseContent active" : "listItem hascollapseContent"}>
                            <i onClick={() => this.props.toggleSideNav(1)} onMouseOver={() => {
                                this.toggleFlyout(true, 4)
                            }} className="zmdi zmdi-network zmdi-lead grey"/>
                            <ListItemText onClick={() => this.handleClick(4)} inset primary="Lead Management"
                                          className="linkTxt"/>
                            {this.state.navListOpen[4] ? <ExpandLess onClick={() => this.handleClick(4)}/> :
                                <ExpandMore onClick={() => this.handleClick(4)}/>}
                        </ListItem>
                        <Collapse in={this.state.navListOpen[4]} timeout="auto" unmountOnExit
                                  className="collapseContent">
                            <List onClick={() => this.props.toggleSideNav(1)} component="div" disablePadding
                                  className="scdlistWrap">
                                {utilCommon.isPermitted(5, 82, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle "/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/admin-leads` ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/admin-leads`}>
                                            <ListItemText inset primary="Leads Admin System" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                                <ListItem className={classes.nested} component="div" className="scdlistItem">
                                    <i className="zmdi zmdi-circle "/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/lead-management` ? "active" : ''}
                                        to={`${process.env.REACT_APP_BASEPATH}/lead-management`}>
                                        <ListItemText inset primary="All Leads" className="scdlistTxt"/>
                                    </Link>
                                </ListItem>
                                {utilCommon.isPermitted(5, 83, 1) &&
                                    <ListItem className={classes.nested} component="div" className="scdlistItem">
                                        <i className="zmdi zmdi-circle "/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/leads-contacted-report` ? "active" : ''}
                                            to={`${process.env.REACT_APP_BASEPATH}/leads-contacted-report`}>
                                            <ListItemText inset primary="Leads Contacted Report" className="scdlistTxt"/>
                                        </Link>
                                    </ListItem>}
                            </List>
                        </Collapse>
                        {/* settings */}
                        {utilCommon.isPermitted(1, 1, 1) && <ListItem component="div"
                                                                      className={this.state.flyopen ? "listItem hascollapseContent settings active" : "listItem hascollapseContent settings"}>
                            <i onClick={() => this.props.toggleSideNav()} onMouseOver={() => {
                                this.toggleFlyout(true);
                            }} className="zmdi zmdi-settings grey"/>
                            <ListItemText onClick={() => this.handleClick()} inset primary="Configuration"
                                          className="linkTxt"/>
                            {this.state.listopen ? <ExpandLess onClick={() => this.handleClick()}/> :
                                <ExpandMore onClick={() => this.handleClick()}/>}
                        </ListItem>}
                        <Collapse in={this.state.listopen} timeout="auto" unmountOnExit className="collapseContent">
                            <Scrollbars style={{"height": "calc(100vh - 438px)"}}>
                                <List component="div" disablePadding className="scdlistWrap">
                                    <ListItem className={classes.nested} component="div"
                                              className="scdlistItem subHead">
                                        <i className="zmdi zmdi-circle"/>
                                        <ListItemText inset primary="User Settings" className="scdlistTxt"/>
                                    </ListItem>
                                    <ListItem className={classes.nested} component="div" className="scdlistItem lavel-2"
                                              onClick={() => this.props.toggleSideNav()}>

                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/user-management` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/user-management`}>
                                                <ListItemText inset primary="User Management" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/teams` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/teams`}>
                                                <ListItemText inset primary="Teams Management" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/security-levels` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/security-levels`}>
                                                <ListItemText inset primary="Security Levels" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/security-roles` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/security-roles`}>
                                                <ListItemText inset primary="Security Roles" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                    </ListItem>
                                    <ListItem className={classes.nested} component="div"
                                              className="scdlistItem subHead">
                                        <i className="zmdi zmdi-circle"/>
                                        <ListItemText inset primary="Carrier Settings" className="scdlistTxt"/>
                                    </ListItem>
                                    <ListItem className={classes.nested} component="div" className="scdlistItem lavel-2"
                                              onClick={() => this.props.toggleSideNav()}>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/carrier-management` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/carrier-management`}>
                                                <ListItemText inset primary="Carrier Management"
                                                              className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/accessorials` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/accessorials`}>
                                                <ListItemText inset primary="Accessorials" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/transit-time` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/transit-time`}>
                                                <ListItemText inset primary="Transit Time" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/facility-type` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/facility-type`}>
                                                <ListItemText inset primary="Loading Facilities"
                                                              className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/fuel-surcharge` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/fuel-surcharge`}>
                                                <ListItemText inset primary="Fuel Surcharge" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                    </ListItem>
                                    <ListItem className={classes.nested} component="div"
                                              className="scdlistItem subHead">
                                        <i className="zmdi zmdi-circle"/>
                                        <ListItemText inset primary="Application Settings" className="scdlistTxt"/>
                                    </ListItem>
                                    <ListItem className={classes.nested} component="div" className="scdlistItem lavel-2"
                                              onClick={() => this.props.toggleSideNav()}>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/global-discount` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/global-discount`}>
                                                <ListItemText inset primary="Discounts & Margins" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/manage-coupon` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/manage-coupon`}>
                                                <ListItemText inset primary="Manage Coupon" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/marketing-emails` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/marketing-emails`}>
                                                <ListItemText inset primary="Marketing emails" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/falvey-terms-conditions` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/falvey-terms-conditions`}>
                                                <ListItemText inset primary="Insurance Terms and Conditions"
                                                              className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/tms-terms-conditions` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/tms-terms-conditions`}>
                                                <ListItemText inset primary="TMS Terms and Conditions"
                                                              className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/financial-settings` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/financial-settings`}>
                                                <ListItemText inset primary="Financial Settings"
                                                              className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/system-wide-notification` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/system-wide-notification`}>
                                                <ListItemText inset primary="System Wide Notification"
                                                              className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/approved-ip` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/approved-ip`}>
                                                <ListItemText inset primary="IP Address" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        <ListItem className={classes.nested} component="div" className="scdlistItem">
                                            <i className="zmdi zmdi-circle "/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/lead-settings` ? "active" : ''}
                                                to={`${process.env.REACT_APP_BASEPATH}/lead-settings`}>
                                                <ListItemText inset primary="Lead Settings" className="scdlistTxt"/>
                                            </Link>
                                        </ListItem>
                                        {utilCommon.isPermitted(1, 2, 1) &&
                                            <ListItem className={classes.nested} component="div"
                                                      className="scdlistItem">
                                                <i className="zmdi zmdi-circle "/>
                                                <Link
                                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/admin-tool` ? "active" : ''}
                                                    to={`${process.env.REACT_APP_BASEPATH}/admin-tool`}>
                                                    <ListItemText inset primary="Admin Tools" className="scdlistTxt"/>
                                                </Link>
                                            </ListItem>}
                                        <ListItem component="div"
                                                  className="scdlistItem">
                                            <i className="zmdi zmdi-circle "/>
                                            <Link className={currentPath === `${process.env.REACT_APP_BASEPATH}/exit-intent-pop-up` ? 'active' : ''}
                                                  to={`${process.env.REACT_APP_BASEPATH}/exit-intent-pop-up`
                                            }>
                                                <ListItemText inset
                                                              primary="Exit-intent pop-up"
                                                              className="scdlistTxt"
                                                />
                                            </Link>
                                        </ListItem>
                                        {utilCommon.isPermitted(1, 103, 1) && <ListItem component="div"
                                                  className="scdlistItem">
                                            <i className="zmdi zmdi-circle "/>
                                            <Link className={currentPath === `${process.env.REACT_APP_BASEPATH}/registration-report-settings` ? 'active' : ''}
                                                  to={`${process.env.REACT_APP_BASEPATH}/registration-report-settings`
                                                  }>
                                                <ListItemText inset
                                                              primary="Registration Report Settings"
                                                              className="scdlistTxt"
                                                />
                                            </Link>
                                        </ListItem>}
                                    </ListItem>
                                </List>
                            </Scrollbars>
                        </Collapse>
                    </List>
                </div>
                {
                    (this.state.flyopen && !this.props.sideNavOpen) &&
                    <div className="left-sub-nav" style={{"paddingRight": "4px"}} onMouseLeave={() => {
                        this.toggleFlyout(false, 5)
                    }}>
                        <p>Configuration</p>
                        <Scrollbars style={{"height": "calc(100vh - 452px)"}}>
                            <ul>
                                <li>
                                    <div className="head"><i className="zmdi zmdi-circle"/>
                                        <a className="link" href="javascript:void(0)">User Settings</a>
                                    </div>
                                    <ul>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/user-management` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/user-management`}>User
                                                Management</Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/teams` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/teams`}>Teams Management</Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/security-levels` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/security-levels`}>Security
                                                Levels</Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/security-roles` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/security-roles`}>Security
                                                Roles</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="head">
                                        <i className="zmdi zmdi-circle"/>
                                        <a className="link" href="javascript:void(0)">Carrier Settings</a>
                                    </div>
                                    <ul>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/carrier-management` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/carrier-management`}>Carrier
                                                Management</Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/accessorials` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/accessorials`}>
                                                Accessorials
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/transit-time` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/transit-time`}>
                                                Transit Time
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/facility-type` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/facility-type`}>
                                                Loading Facilities
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/fuel-surcharge` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/fuel-surcharge`}>
                                                Fuel Surcharge
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="head">
                                        <i className="zmdi zmdi-circle"/>
                                        <a className="link" href="javascript:void(0)"> Application Settings</a>
                                    </div>
                                    <ul>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/global-discount` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/global-discount`}>
                                                Discounts & Margins
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath ===`${process.env.REACT_APP_BASEPATH}/manage-coupon` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/manage-coupon`}>
                                                Manage Coupon
                                            </Link>
                                        </li>

                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath ===`${process.env.REACT_APP_BASEPATH}/marketing-emails` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/marketing-emails`}>
                                                Marketing emails
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath ===`${process.env.REACT_APP_BASEPATH}/rewards-settings` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/rewards-settings`}>
                                                Rewards Settings
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/falvey-terms-conditions` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/falvey-terms-conditions`}>
                                                Insurance Terms and Conditions
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/tms-terms-conditions` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/tms-terms-conditions`}>
                                                TMS Terms and Conditions
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/financial-settings` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/financial-settings`}>
                                                Financial Settings
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/system-wide-notification` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/system-wide-notification`}>
                                                System Wide Notification
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/approved-ip` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/approved-ip`}>
                                                IP Address
                                            </Link>
                                        </li>
                                        <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/lead-settings` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/lead-settings`}>
                                                Lead Settings
                                            </Link>
                                        </li>
                                        {utilCommon.isPermitted(1, 2, 1) && <li><i className="zmdi zmdi-circle "/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/admin-tool` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/admin-tool`}>
                                                Admin Tool
                                            </Link>
                                        </li>}
                                        {
                                            utilCommon.isPermitted(1, 100, 1) && <li><i className="zmdi zmdi-circle "/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/exit-intent-pop-up` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/exit-intent-pop-up`}>
                                                Exit-intent pop-up
                                            </Link>
                                        </li>
                                        }
                                        {utilCommon.isPermitted(1, 103, 1) && <li><i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/registration-report-settings` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/registration-report-settings`}>
                                                Registration Report Settings
                                            </Link>
                                        </li>}
                                    </ul>
                                </li>
                            </ul>
                        </Scrollbars>
                    </div>
                }
                {(this.state.customerflyopen && !this.props.sideNavOpen) &&
                    <div className="left-sub-nav customer" onMouseLeave={() => {
                        this.toggleFlyout(false, 1)
                    }}>
                        <p>Customers</p>
                        <ul>
                            {utilCommon.isPermitted(2, 6, 1) && <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/customer-management` ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/customer-management`}>My Customers</Link>
                            </li>}
                            <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/add-customer-management` ? "link active" : 'link'}
                                    to={{
                                        pathname: process.env.REACT_APP_BASEPATH + "/add-customer-management",
                                        params: {editMode: false}
                                    }}>Add Customer</Link>
                            </li>
                        </ul>
                    </div>
                }
                {/* TL shipment */}
                {(this.state.navFlyOpen[7] && !this.props.sideNavOpen) && utilCommon.isPermitted(7, 57, 1) &&
                    <div className="left-sub-nav tlshipment" onMouseLeave={() => {
                        this.toggleFlyout(false, 7)
                    }}>
                        <p>TL Shipments</p>
                        <ul>
                            {utilCommon.isPermitted(7, 57, 1) &&
                            <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentFullPath.includes('shipmentMode[]=3') ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/shipment-management?${this.getTlParams()}`}>My TL Shipments</Link>
                            </li>}
                            {utilCommon.isPermitted(7, 57, 1) &&
                            <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentFullPath === `${process.env.REACT_APP_BASEPATH}/quote?shipmentMode=3` ? "link active" : 'link'}
                                    to={{
                                        pathname: process.env.REACT_APP_BASEPATH + "/quote",
                                        search: "?shipmentMode=3"
                                    }}
                                >New TL Shipment</Link>
                            </li>}
                        </ul>
                    </div>
                }
                {/* LTL shipment */}
                {(this.state.navFlyOpen[2] && !this.props.sideNavOpen) &&
                    <div className="left-sub-nav shipment" onMouseLeave={() => {
                        this.toggleFlyout(false, 2)
                    }}>

                        <p>LTL Shipments</p>
                        <ul>
                            {utilCommon.isPermitted(4, 18, 1) && <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentFullPath.includes('shipmentMode[]=1') ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/shipment-management?${this.getLtlParams()}`}>My LTL Shipments</Link>
                            </li>}
                            <li><i className="zmdi zmdi-circle"/>
                                {/* Changed to New shipment (justin comment) */}
                                <Link
                                    className={currentFullPath === `${process.env.REACT_APP_BASEPATH}/quote?shipmentMode=1` ? "link active" : 'link'}
                                    to={{
                                        pathname: process.env.REACT_APP_BASEPATH + "/quote",
                                        search: "?shipmentMode=1"
                                    }}>New LTL Shipment</Link>
                            </li>
                        </ul>
                    </div>
                }
                {/* Reports */}
                {(this.state.navFlyOpen[3] && !this.props.sideNavOpen) &&
                    <div className="left-sub-nav report" onMouseLeave={() => {
                        this.toggleFlyout(false, 3)
                    }}>
                        <p>Reports</p>
                            <ul>
                                {utilCommon.isPermitted(3, 52, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/alert-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/alert-report`}>Alert Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 88, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/sales-tracking-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/sales-tracking-report`}>Sales Tracking
                                        Report</Link>

                                </li>}
                                {utilCommon.isPermitted(3, 89, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/shippers-by-area-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/shippers-by-area-report`}>Frequent
                                        Shippers by Area</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 17, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/processing-review-queue` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/processing-review-queue`}>Processing
                                        Review Queue</Link>
                                </li>}

                                {/*FGL-156*/}
                                {/*<li><i className="zmdi zmdi-circle"/>*/}
                                {/*    <Link className={currentPath ===`${process.env.REACT_APP_BASEPATH}/customer-discount-report` ? "link active" : 'link'} to={`${process.env.REACT_APP_BASEPATH}/customer-discount-report`} >Customer Discount Report</Link>*/}
                                {/*</li>*/}
                                {utilCommon.isPermitted(3, 90, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/registration-reports` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/registration-reports`}>Registration
                                        Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 91, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/carrier-performance-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/carrier-performance-report`}>Carrier
                                        Performance Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 92, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/statistical-reports` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/statistical-reports`}>Statistical
                                        Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 93, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/top-dog-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/top-dog-report`}>Top Dog Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 97, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/agent-bonus-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/agent-bonus-report`}>Agent Bonus Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3,81,1) &&
                                    <li>
                                        <i className="zmdi zmdi-circle"/>
                                        <Link
                                            className={currentPath === `${process.env.REACT_APP_BASEPATH}/covered-report` ? "link active" : 'link'}
                                            to={`${process.env.REACT_APP_BASEPATH}/covered-report`}>Covered Report</Link>
                                    </li>
                                }
                                {utilCommon.isPermitted(3, 94, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/agent-revenue-comparison-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/agent-revenue-comparison-report`}>Agent
                                        Revenue Comparison Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 95, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/customer-trend-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/customer-trend-report`}>Customer Trend
                                        Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 96, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/daily-sales-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/daily-sales-report`}>Daily Sales
                                        Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 97, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/agent-conversion-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/agent-conversion-report`}>Agent
                                        Conversion Report</Link>
                                </li>}
                                {utilCommon.isPermitted(3, 98, 1) && <li><i className="zmdi zmdi-circle"/>
                                    <Link
                                        className={currentPath === `${process.env.REACT_APP_BASEPATH}/fc-quote-results-report` ? "link active" : 'link'}
                                        to={`${process.env.REACT_APP_BASEPATH}/fc-quote-results-report`}>FC Quote
                                        Results Report</Link>
                                </li>}
                            </ul>
                    </div>
                }
                {/* Leads */}
                {(this.state.navFlyOpen[4] && !this.props.sideNavOpen) &&
                    <div className="left-sub-nav lead" onMouseLeave={() => {
                        this.toggleFlyout(false, 4)
                    }}>
                        <p>Lead Management</p>
                        <ul>
                            {utilCommon.isPermitted(5, 82, 1) && <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/admin-leads` ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/admin-leads`}>Leads Admin System</Link>
                            </li>}
                            <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/lead-management` ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/lead-management`}>All Leads</Link>
                            </li>
                            {utilCommon.isPermitted(5, 83, 1) && <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/leads-contacted-report` ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/leads-contacted-report`}>Leads Contacted Report</Link>
                            </li>}
                        </ul>
                    </div>
                }
                {/* Accounting */}
                {(this.state.navFlyOpen[5] && !this.props.sideNavOpen) &&
                    <div className="left-sub-nav customerAR" onMouseLeave={() => {
                        this.toggleFlyout(false, 5)
                    }}>
                        <p>Accounting</p>
                        <ul>
                            {utilCommon.isPermitted(6, 45, 1) && <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/customer-ar-report` ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/customer-ar-report`}>Customer AR
                                    Report</Link>
                            </li>}
                            {utilCommon.isPermitted(6, 44, 1) && <li><i className="zmdi zmdi-circle"/>
                                <Link
                                    className={currentPath === `${process.env.REACT_APP_BASEPATH}/daily-booking-report` ? "link active" : 'link'}
                                    to={`${process.env.REACT_APP_BASEPATH}/daily-booking-report`}>Daily Bookings with
                                    Insurance Report</Link>
                            </li>}
                            {(utilCommon.isPermitted(3, 41, 1) || utilCommon.isPermitted(3, 14, 1) || utilCommon.isPermitted(3, 15, 1) || utilCommon.isPermitted(3, 16, 1)) &&
                                <li>
                                    {/* BA Queue SubMenu */}
                                    <div className="head">
                                        <i className="zmdi zmdi-circle"/>
                                        {<a className="link" href="javascript:void(0)">Billing Adjustment</a>}
                                    </div>
                                    <ul>
                                        {false && utilCommon.isPermitted(3, 41, 1) && <li>
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/shipment-without-carrier-bill` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/shipment-without-carrier-bill`}>Shipments
                                                without Carrier Bill</Link>
                                        </li>}
                                        {utilCommon.isPermitted(3, 14, 1) && <li>
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/new-ba-queue` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/new-ba-queue`}>New BA
                                                Queue</Link>
                                        </li>}
                                        {utilCommon.isPermitted(3, 15, 1) && <li>
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/in-progress-ba-queue` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/in-progress-ba-queue`}>In
                                                Progress BA Queue</Link>
                                        </li>}
                                        {utilCommon.isPermitted(3, 51, 1) && <li>
                                            <i className="zmdi zmdi-circle"/>
                                            <Link
                                                className={currentPath === `${process.env.REACT_APP_BASEPATH}/processed-ba-queue` ? "link active" : 'link'}
                                                to={`${process.env.REACT_APP_BASEPATH}/processed-ba-queue`}>Completed BA
                                                Queue</Link>
                                        </li>}
                                    </ul>
                                </li>}
                        </ul>
                    </div>
                }
            </div>
        );
    }
}

SideNavbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideNavbar);
